const auth = async () => {
    const loggedIn = localStorage.getItem('loggedIn');
    if (loggedIn) {
        const authData = JSON.parse(localStorage.getItem('auth_data'));
        return { isAuthenticated: true, authData: authData };
    } else {
        let json;
        try {
            json = await fetch(`https://api.aquabot.io/public/session`, { credentials: 'include' }).then(r => r.json());
        } catch(e) {
            console.log(e);
        };
        if (!json || json.success == false) {
            return { isAuthenticated: false, authData: null };
        } else {
            localStorage.setItem('loggedIn', true);
            localStorage.setItem('auth_method', JSON.stringify(json.auth_method));
            localStorage.setItem('auth_data', JSON.stringify(json.auth_data));
            return { isAuthenticated: true, authData: json.auth_data };
        };
    };
};

export default auth;